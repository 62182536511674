import { useEffect, useState, useRef } from "react";
import { Box, styled, Button, Checkbox, FormControl, FormControlLabel, InputLabel, Grid, Icon, Radio, RadioGroup, MenuItem, OutlinedInput, InputAdornment, IconButton, Typography, Tab, Tabs } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RestartAlt from '@mui/icons-material/RestartAlt';
import { LoadingButton } from '@mui/lab';
import { Breadcrumb, SimpleCard } from "app/components";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Span } from "app/components/Typography";
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';

import { GetPosts, CreatePost, UpdatePost } from "../../../store/actions/postAction"
import { GetUsers } from "../../../store/actions/userAction"

import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "../../../store/types/commentTypes"

import { CLIENT_URL, SERVER_URL } from '../../../config/keys'

import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CreateComment, DeleteComment, GetComments, UpdateComment } from "store/actions/commentAction";

const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CommentForm = () => {
    /* const { myInfo } = useSelector((state) => state.Users);
    console.log(myInfo) */
    const dispatch = useDispatch()
    const navigate = useNavigate();

    let { commentid } = useParams();
    // console.log(commentid);

    const { comments, errorMessage, successMessage } = useSelector((state) => state.Comments);

    const { myInfo, users } = useSelector((state) => state.Users);

    const [comment, setComment] = useState(false);

    const [loading, setLoading] = useState(false);

    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });

    useEffect(() => {
        dispatch(GetUsers()).then(() => {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })

            dispatch(GetComments({ /* author: currentUser._id */ })).then(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            })
        })
    }, []);

    let currentUser = '';

    useEffect(() => {
        if (users && users.length > 0 && myInfo && Object.keys(myInfo).length > 0) {
            currentUser = users.find(item => item.email === myInfo.email);

            if (commentid && commentid.length > 0 && comments) {
                dispatch(GetComments({ /* author: currentUser._id */ })).then(() => {
                    dispatch({ type: SUCCESS_MESSAGE_CLEAR })
                })
            }
        }
    }, [users, myInfo])

    useEffect(() => {
        if (commentid && commentid.length > 0 && comments) {
            // console.log(comments)
            const currentComment = comments.find(item => item._id.toString() === commentid.toString());
            if (currentComment) {
                // console.log('first')
                setComment(currentComment);
            } else {
                // console.log('second')
                setCallbackResponse({ type: "error", message: "Invalid comment data." });
            }
        } else {
            setComment({ /* author: currentUser._id */ });
        }
    }, [comments, commentid])

    const handleChange = (event) => {
        // console.log(event.target.name)
        setComment(comment => ({ ...comment, [event.target.name]: event.target.value }));
    };

    const handleDropdownChange = (field, e) => {
        // console.log(field, value)
        setComment(comment => ({ ...comment, [field]: e.value }));
    };

    const handleSubmit = (event) => {
        setLoading(true);
        if (!comment) {
            setCallbackResponse({ type: "error", message: "Invalid form data." });
            return;
        } else {
            if (comment._id) {
                dispatch(UpdateComment(comment));
            } else {
                dispatch(CreateComment(comment));
            }
        }
    };

    useEffect(() => {
        /* if (successMessage.create) {
            setCallbackResponse({ type: "success", message: successMessage.create });
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
                navigate("/comments/");
            }, 2000);
        } */

        if (successMessage.update) {
            setCallbackResponse({ type: "success", message: successMessage.update });
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
                navigate("/comments/");
            }, 2000);
        }

        if (errorMessage) {
            setCallbackResponse({ type: "error", message: errorMessage });
            setLoading(false);
            dispatch({ type: ERROR_CLEAR })
        }
    }, [successMessage, errorMessage])

    let vertical = 'top';
    let horizontal = 'right';

    const statusOptions = [
        { value: 'hold', label: 'Hold' },
        { value: 'approved', label: 'Approved' }
    ];

    const authorOptions = [];
    users && users.length > 0 && users.map(user => (
        authorOptions.push({ value: user._id, label: `${user.fname} ${user.lname}` })
    ))

    const handleDelete = (data) => {
        Swal.fire({
            // icon: "info",
            title: "Are you sure?",
            showCancelButton: true,
            text: "This action cannot be undone!",
            confirmButtonText: 'Yes',
            cancelButtonText: `No`,
            cancelButtonColor: "#EE7364"
        }).then((result) => {
            if (result.isConfirmed) {
                // currentUser = users.find(item => item.email === myInfo.email);
                dispatch(DeleteComment(data)).then(() => {
                    dispatch({ type: SUCCESS_MESSAGE_CLEAR });
                    navigate('/comments');
                });
            }
        });
    };

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Edit Comment" }]} />
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    // onClose={setCallbackResponse({ type: "", message: "" })}
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>

            <SimpleCard title="Edit Comment">
                <ValidatorForm onSubmit={handleSubmit} method="comment" onError={() => null} autoComplete="off" enctype="multipart/form-data">
                    <Grid container spacing={6}>
                        <Grid item lg={9} md={9} sm={12} xs={12} sx={{ mt: 2 }}>
                            <label className="form-label">Author Name</label>
                            <TextField
                                type="text"
                                name="commentAuthor"
                                // label="Title"
                                onChange={handleChange}
                                value={comment.commentAuthor || ""}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                autoComplete="off"
                            />

                            <label className="form-label">Author Email</label>
                            <TextField
                                type="email"
                                name="commentAuthorEmail"
                                // label="Title"
                                onChange={handleChange}
                                value={comment.commentAuthorEmail || ""}
                                // validators={["required"]}
                                // errorMessages={["this field is required"]}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                autoComplete="off"
                            />

                            <label className="form-label">Author URL</label>
                            <TextField
                                type="text"
                                name="commentAuthorUrl"
                                // label="Title"
                                onChange={handleChange}
                                value={comment.commentAuthorUrl || ""}
                                // validators={["required"]}
                                // errorMessages={["this field is required"]}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                autoComplete="off"
                            />

                            <label className="form-label">Comment</label>
                            <TextField
                                type="text"
                                name="commentContent"
                                value={comment.commentContent || ""}
                                onChange={handleChange}
                                errorMessages={["this field is required"]}
                                // label="Excerpt"
                                // validators={["required", "minStringLength: 4", "maxStringLength: 9"]}
                                multiline
                                rows={4}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Box sx={{ mb: 2 }}>
                                <label className="form-label">Submitted On</label><br />
                                {comment.updatedAt ? new Date(comment.updatedAt).toISOString() : ''}
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <label className="form-label">Status</label>
                                <Select placeholder="Select Status" name="commentApproved" options={statusOptions} value={statusOptions.find(option => option.value === comment.commentApproved)} onChange={(e) => handleDropdownChange('commentApproved', e)} />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <Button fullWidth={true}
                                    type="button"
                                    color="secondary"
                                    variant="outlined"
                                    sx={{ my: 2 }}
                                    onClick={() => handleDelete({ _id: commentid })}
                                >
                                    <Icon>delete</Icon>
                                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Delete</Span>
                                </Button>
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <LoadingButton fullWidth={true}
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    variant="contained"
                                    sx={{ my: 2 }}
                                >
                                    <Icon>save</Icon>
                                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </SimpleCard>
        </Container >
    );
};

export default CommentForm;