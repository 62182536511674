import { Avatar, Box, Button, Card, CardContent, Icon, IconButton, InputBase, Link, Paper, Stack, TextField, Typography, styled } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Span } from "app/components/Typography";
import CommentsTable from "./CommentsTable";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const AllComments = () => {
    const location = useLocation();
    const currentPathname = location.pathname;
    const currentPageName = currentPathname.includes('/posts/post') ? 'All Posts' :
        currentPathname.includes('/posts/page') ? 'All Pages' :
            '';
    // console.log(currentPathname);

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const filterOptions = [
        { value: 'All', label: 'All', isFixed: true },
        { value: 'Approved', label: 'Approved' },
        { value: 'Spam', label: 'Spam' },
        { value: 'Trash', label: 'Trash', },
    ];

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Comments" }]} />
            </Box>

            <SimpleCard title="Comments">
                <CommentsTable />
            </SimpleCard>
        </Container>
    );
};

export default AllComments;