import { createContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { USER_LOGOUT_SUCCESS_CLEAR, USER_LOGIN_SUCCESS, SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "../../store/types/userTypes"
import { SERVER_URL } from '../../config/keys'
import { GetCurrentUser } from "../../store/actions/userAction"
const initialState = {
    user: null,
    isInitialised: false,
    isAuthenticated: false
};

const reducer = (state, action) => {
    // console.log(31, action.type);

    switch (action.type) {
        case 'INIT': {
            // console.log(action.payload);
            const { isAuthenticated, user } = action.payload;
            return { ...state, isAuthenticated, isInitialised: true, user };
        }

        case 'LOGIN': {
            const { user } = action.payload;
            return { ...state, isAuthenticated: true, user };
        }

        case 'LOGOUT': {
            return { ...state, isAuthenticated: false, user: null };
        }

        case 'REGISTER': {
            const { user } = action.payload;

            return { ...state, isAuthenticated: true, user };
        }

        default:
            return state;
    }
};

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    // login: () => {},
    // logout: () => {},
    // register: () => {}
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { loading, error, successMessage, authenticate, myInfo, logoutSuccess } = useSelector((state) => state.Users)
    const navigate = useNavigate();

    useEffect(() => {
        if (logoutSuccess) {
            console.log(logoutSuccess, "clearing ls");
            dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: {} } });
            dispatch({ type: USER_LOGOUT_SUCCESS_CLEAR });
            localStorage.clear();
        }
    }, [logoutSuccess])

    useEffect(() => {
        // console.log(authenticate);
        if (
            // authenticate
            localStorage.getItem('auth')
            && window.location.pathname !== "/signin"
            && window.location.pathname !== "/404"
            && `/${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}` !== "/reset-password"
            && window.location.pathname !== "/reset-password"
            && window.location.pathname !== "*"
        ) {
            // let userRoutes = [...children?.props?.children[1]?.props?.match?.route?.children]
            // console.log(children)
            let userRoutes = [];
            if (
                children
                && children.props
                && children.props.children[1]
                && children.props.children[1].props
                && children.props.children[1].props.match
                && children.props.children[1].props.match.route
                && children.props.children[1].props.match.route.children
            ) {
                userRoutes = [...children.props.children[1].props.match.route.children];
            } else {
                // Handle the case where one of the properties is not defined or does not have the expected structure.
                console.error("Error: Unable to retrieve userRoutes. Check the structure of the properties.");
            }

            // console.log(81, children);
            let curUserRoutes = []
            if (userRoutes && userRoutes.length > 0) {
                // console.log(userRoutes)
                for (let i = 0; i < userRoutes.length; i++) {
                    let inc = userRoutes[i]?.auth?.includes(myInfo?.role)
                    if (inc) {
                        // console.log("role patch:--")
                        curUserRoutes.push(userRoutes[i])
                    }
                }
            }
            if (curUserRoutes && curUserRoutes.length > 0) {
                // console.log('routes condn:---')
                children.props.children[1].props.match.route.children = curUserRoutes
            }
            // console.log(curUserRoutes)
        } else {
            // console.log("calling current user --- dispatch")
            // console.log(authenticate, myInfo)
            dispatch(GetCurrentUser())
            // dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: {} } });
        }

        if (localStorage.getItem('auth')) {
            dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: myInfo } });
        } else {
            dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: {} } });
        }
    }, [authenticate, myInfo])

    // SHOW LOADER
    if (!state.isInitialised) return <MatxLoading />;

    return (
        <AuthContext.Provider value={{ ...state, method: 'JWT' }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthContext;
