
export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'

export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS'
export const SETTING_UPDATE_FAIL = 'SETTING_UPDATE_FAIL'

export const SETTING_GET_SUCCESS = 'SETTING_GET_SUCCESS'
export const SETTING_GET_FAIL = 'SETTING_GET_FAIL'

export const TEST_MAIL_SUCCESS = 'TEST_MAIL_SUCCESS'
export const TEST_MAIL_FAIL = 'TEST_MAIL_FAIL'