import {
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_FAIL,
    COMMENT_GET_SUCCESS,
    COMMENT_GET_FAIL,
    COMMENT_UPDATE_SUCCESS,
    COMMENT_UPDATE_FAIL,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_FAIL
} from "../types/commentTypes";

import axios from "axios";

import { SERVER_URL } from '../../config/keys';
import { IpInfo } from "app/utils/IpInfo";

export const userAuth = () => {
    axios.interceptors.request.use(
        (config) => {
            // config.headers.authorization = `Bearer ${token}`;
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            };

            config.withCredentials = true
            // console.log(config)
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const GetComments = (data) => {
    // console.log(data)
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/comment/get-comments`, data);
            // console.log(response)
            dispatch({
                type: COMMENT_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    comments: response.data.comments
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: COMMENT_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const CreateComment = (data) => {
    // console.log(data)
    userAuth();
    return async (dispatch) => {
        try {
            /* let getuserHistory = await IpInfo();
            console.log(getuserHistory);
            return false; */

            let response = await axios.post(`${SERVER_URL}/api-v1/comment/create`, data);
            // console.log(response)
            dispatch({
                type: COMMENT_CREATE_SUCCESS,
                payload: {
                    comments: response.data.comments,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: COMMENT_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const UpdateComment = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.put(`${SERVER_URL}/api-v1/comment/update-comment`, data);
            // console.log(response)
            dispatch({
                type: COMMENT_UPDATE_SUCCESS,
                payload: {
                    comments: response.data.comments,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: COMMENT_UPDATE_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    }
}

export const DeleteComment = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/comment/delete-comment`, data);
            // console.log(response)
            dispatch({
                type: COMMENT_DELETE_SUCCESS,
                payload: {
                    comments: response.data.comments,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: COMMENT_DELETE_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
}