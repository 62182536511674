import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { UserReducer } from "./reducers/userReducer";
import { PostReducer } from "./reducers/postReducer";
import { SettingReducer } from "./reducers/settingReducer";
import { CommentReducer } from "./reducers/commentReducer";
import { TermsReducer } from "./reducers/termReducer";

const rootReducer = combineReducers({
  Users: UserReducer,
  Posts: PostReducer,
  Terms: TermsReducer,
  Comments: CommentReducer,
  Settings: SettingReducer
});

const store = configureStore({ reducer: rootReducer });
export default store;