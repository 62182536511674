import {
    Box,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    useTheme,
    Avatar,
    Hidden,
    Grid,
    Button,
    Typography,
    Modal,
    TextField
} from "@mui/material";

import { useState, useEffect } from "react";
import { Paragraph, Span } from 'app/components/Typography';
import { CLIENT_URL } from 'config/dev';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { MD5 } from 'crypto-js';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CreateComment, UpdateComment, GetComments, DeleteComment } from "../../../store/actions/commentAction"
import { GetUsers } from "../../../store/actions/userAction"
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "../../../store/types/commentTypes"
import ArticleIcon from '@mui/icons-material/Article';
import { LoadingButton } from "@mui/lab";

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Comments</Box>
        </StyledGridOverlay>
    );
}

const CommentsTable = () => {
    const location = useLocation();

    let { postid } = useParams();
    // console.log(postid)

    let initComment = {
        "commentPostId": "65f56645ff1204c41699cf10",
        "commentAuthor": "",
        "commentAuthorEmail": "",
        "commentAuthorUrl": "",
        "commentAuthorIp": "",
        "commentContent": "This is a test comment",
        "commentApproved": "hold",
        "commentAgent": "",
        "commentParent": null,
        "userId": null
    };

    const [comment, setComment] = useState(false);

    const [callbackResponse, setCallbackResponse] = useState({ type: "", message: "" });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { myInfo, users } = useSelector((state) => state.Users);

    const { comments, errorMessage, successMessage } = useSelector((state) => state.Comments);
    // console.log(comments);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        dispatch(GetUsers()).then(() => {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
        })
    }, []);

    let currentUser = '';
    useEffect(() => {
        if (users && users.length > 0 && myInfo && Object.keys(myInfo).length > 0) {
            // currentUser = users.find(item => item.email === myInfo.email);
            let args = {};
            if (postid) {
                args = { commentPostId: postid };
            }
            dispatch(GetComments(args)).then(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            })
        }
    }, [users, myInfo])

    const handleCreate = () => {
        dispatch(CreateComment(initComment));
    };

    const handleChange = (event) => {
        // console.log(event.target.name)
        setComment(comment => ({ ...comment, [event.target.name]: event.target.value }));
    };

    const handleApprove = (data) => {
        // console.log(data)
        // setLoading(true);
        if (!data || !data._id) {
            setCallbackResponse({ type: "error", message: "Invalid comment data." });
            return;
        }
        dispatch(UpdateComment(data)).then(() => {
            setCallbackResponse({ type: "success", message: "Comment " + data.commentApproved + "." });
        });
    };

    const handleReplyModal = (data) => {
        // console.log(data)
        if (!data) {
            setCallbackResponse({ type: "error", message: "Invalid comment data." });
            return;
        }
        // console.log(myInfo);
        let replyComment = {
            "commentPostId": data.commentPostId,
            "commentAuthor": `${myInfo.fname} ${myInfo.lname}`,
            "commentAuthorEmail": `${myInfo.email}`,
            "commentAuthorUrl": `${myInfo.website}`,
            "commentAuthorIp": "",
            "commentContent": "",
            "commentApproved": "approved",
            "commentAgent": "",
            "commentParent": data._id,
            "userId": null
        };

        setComment(replyComment);
        handleOpen();
    };

    const handleReply = () => {
        if (!comment || comment.commentContent === '') {
            setCallbackResponse({ type: "error", message: "Invalid comment data." });
            return;
        }

        dispatch(CreateComment(comment)).then(() => {
            setCallbackResponse({ type: "success", message: "Reply saved successfully." });
            setComment(false);
            handleClose();
        });
    };

    const handleDelete = (data) => {
        Swal.fire({
            // icon: "info",
            title: "Are you sure?",
            showCancelButton: true,
            text: "This action cannot be undone!",
            confirmButtonText: 'Yes',
            cancelButtonText: `No`,
            cancelButtonColor: "#EE7364"
        }).then((result) => {
            if (result.isConfirmed) {
                // currentUser = users.find(item => item.email === myInfo.email);
                dispatch(DeleteComment(data));
            }
        });
    };

    useEffect(() => {
        if (successMessage.delete) {
            setCallbackResponse({ type: "success", message: successMessage.delete });
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            }, 2000);
        }

        if (errorMessage) {
            setCallbackResponse({ type: "error", message: errorMessage });
            dispatch({ type: ERROR_CLEAR })
        }
    }, [successMessage, errorMessage])

    const columns = [
        {
            field: 'id', headerName: 'ID', width: 50,
            renderCell: (params) => (
                <strong>{params.row.id}</strong>
            ),
        },
        {
            field: 'commentPostId', headerName: 'Attached To', width: 150,
            renderCell: (params) => {
                return <><ArticleIcon style={{ cursor: 'pointer' }} onClick={() => navigate(`/edit-post/${params.row.commentPostId}`)} /> {params.row.commentPost.postTitle}</>;
            },
        },
        {
            field: 'commentAuthor', headerName: 'Author', width: 150,
            renderCell: (params) => {
                return <div>{params.row.commentAuthor !== '' ? params.row.commentAuthor : 'Guest'}</div>;
            },
        },
        { field: 'commentContent', headerName: 'Comment', width: 350 },
        {
            field: 'commentParent', headerName: 'In Response To', width: 150,
            renderCell: (params) => {
                let commentParent = null;
                if (params.row.commentParent) {
                    commentParent = comments.findIndex(item => item._id.toString() === params.row.commentParent.toString());
                }
                return <div>{commentParent + 1}</div>;
            },
        },
        {
            field: 'Status', headerName: 'Status', width: 100,
            renderCell: (params) => {
                return <div>{params.row.commentApproved}</div>;
            },
        },
        {
            field: 'createdAt', headerName: 'Submitted On', width: 100,
            renderCell: (params) => {
                const formattedDate = new Date(params.row.updatedAt).toISOString().split('T')[0];
                return <div>{formattedDate}</div>;
            },
        },
        {
            field: 'action', headerName: '', width: 200, sortable: false, disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                        {params.row.commentApproved !== 'approved' ?
                            <IconButton onClick={() => {
                                // console.log(params.row)
                                handleApprove({ ...params.row, commentApproved: 'approved' })
                            }}>
                                <Icon fontSize="small">check</Icon>
                            </IconButton>
                            : <IconButton onClick={() => {
                                // console.log(params.row)
                                handleApprove({ ...params.row, commentApproved: 'hold' })
                            }}>
                                <Icon fontSize="small">gpp_bad</Icon>
                            </IconButton>}

                        <IconButton onClick={() => handleReplyModal(params.row)}>
                            <Icon fontSize="small">reply</Icon>
                        </IconButton>

                        <IconButton onClick={() => navigate(`/edit-comment/${params.row._id}`)}>
                            <Icon fontSize="small">edit</Icon>
                        </IconButton>

                        <IconButton onClick={() => handleDelete({ _id: params.row._id })}>
                            <Icon fontSize="small" color="error">delete</Icon>
                        </IconButton>
                    </div>
                )
            },
        },
    ];

    let rows = [];
    if (comments) {
        // include only the specific post type in this list
        rows = comments;
    }
    let idCounter = 1;
    const generateIncrementalId = (() => {
        return idCounter++;
    });

    const getRowId = (row) => row._id;

    const dataWithIds = rows.map((row) => ({ ...row, id: generateIncrementalId() }));
    // console.log(dataWithIds)

    let vertical = 'top';
    let horizontal = 'right';

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 2,
    };

    return (
        <Box width="100%" overflow="auto">
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    // onClose={setCallbackResponse({ type: "", message: "" })}
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>
            <button onClick={handleCreate}>Create Dummy Comment</button>
            <DataGrid
                autoHeight
                columns={columns}
                rows={dataWithIds}
                getRowId={getRowId} // Specify the getRowId function
                slots={{
                    toolbar: GridToolbar, // Use the GridToolbar component for the toolbar
                    noRowsOverlay: CustomNoRowsOverlay, // Use your custom component for the no rows overlay
                }}
                sx={{ '--DataGrid-overlayHeight': '300px' }}
                checkboxSelection={false}
            />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
                        <label className="form-label">Reply</label>
                        <TextField
                            type="text"
                            name="commentContent"
                            value={comment.commentContent || ""}
                            onChange={handleChange}
                            errorMessages={["this field is required"]}
                            // label="Excerpt"
                            // validators={["required", "minStringLength: 4", "maxStringLength: 9"]}
                            multiline
                            rows={4}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            autoComplete="off"
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <LoadingButton fullWidth={true}
                            type="submit"
                            color="primary"
                            // loading={loading}
                            variant="contained"
                            sx={{ my: 2 }}
                            onClick={handleReply}
                        >
                            <Icon>save</Icon>
                            <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default CommentsTable;