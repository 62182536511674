import { useEffect, useState, useRef } from "react";
import { CLIENT_URL, SERVER_URL } from '../config/keys'

import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import NotFound from "app/views/sessions/NotFound";
import { GetPosts } from "store/actions/postAction";
import { Helmet } from "react-helmet";

// import * as themes from '../themes';

// Now you can access individual themes as properties of the 'themes' object
// console.log(themes.starter);

export default function Page() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // let { postSlug } = useParams();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    const { posts, errorMessage, successMessage } = useSelector((state) => state.Posts);
    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });

    if (!pathname.includes('admin')) {
        import('../themes').then((themes) => {
            // Now you can use the themes object here
            // console.log(themes);
        }).catch((error) => {
            console.error('Error importing themes:', error);
        });
    }

    useEffect(() => {
        // console.log(posts)
        if (pathname && pathname.split('/')[1].length > 0) {
            // setTimeout(() => {
            dispatch(GetPosts({ postSlug: pathname.split('/')[1] }))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
            // }, 5000);
        } else {
            setLoading(false);
            setCallbackResponse({ type: "error", message: "Invalid post data." });
        }
    }, [dispatch, pathname])

    return (
        <>
            {loading ? (
                <div class="matx-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            ) : (
                // Show content if dispatch has completed
                posts && posts.length > 0 ? (
                    <>
                        <Helmet>
                            <meta property="og:title" content={'Title of your page'} />
                            <meta property="og:description" content={'Description of your page'} />
                            <meta property="og:image" content={'URL of the image you want to use'} />
                            <meta property="og:url" content={'URL of your page'} />
                            <meta property="og:type" content="website" />
                            <meta name="twitter:title" content={'Title of your page'} />
                            <meta name="twitter:description" content={'Description of your page'} />
                            <meta name="twitter:image" content={'URL of the image you want to use'} />
                            <meta name="twitter:card" content="summary_large_image" />
                        </Helmet>
                        <div>{posts[0].postTitle}</div>
                        <div>{posts[0].postContent}</div>
                        <div>{posts[0].postExcerpt}</div>
                    </>
                ) : (
                    // Show NotFound component if no posts found
                    <NotFound />
                )
            )}
        </>
    )
}
