import { Box, styled, Typography, ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton, Modal, Grid, Paper } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

import { Breadcrumb, SimpleCard } from "app/components";
import Dropzone from 'react-dropzone'
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetPosts, CreatePost, UpdatePost, CreateMediaPost, GetMediaPosts } from "../../../store/actions/postAction"
import { GetUsers } from "../../../store/actions/userAction"
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "../../../store/types/postTypes"
import { CLIENT_URL, SERVER_URL } from '../../../config/keys'
import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
        author: '@bkristastucchio',
        /* rows: 2,
        cols: 2, */
        featured: true,
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
        author: '@rollelflex_graphy726',
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        author: '@helloimnik',
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
        author: '@nolanissac',
        // cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
        author: '@hjrc33',
        // cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
        author: '@arwinneil',
        // rows: 2,
        // cols: 2,
        featured: true,
    },
    {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
        author: '@tjdragotta',
    },
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
        author: '@katie_wasserman',
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
        author: '@silverdalex',
        // rows: 2,
        // cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
        author: '@shelleypauls',
    },
    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
        author: '@peterlaster',
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
        author: '@southside_customs',
        // cols: 2,
    },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Media = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [openImage, setOpenImage] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = (item) => {
        console.log(item);
        setOpenImage(item.postImage)
        setOpen(true)
    };

    const handleClose = () => setOpen(false);

    const { myInfo, users } = useSelector((state) => state.Users);
    // console.log(myInfo)
    const { posts, successMessage, errorMessage } = useSelector((state) => state.Posts);

    const [post, setPost] = useState({ postTitle: "", postContent: "", postExcerpt: "", postType: "Post", postSlug: "", postStatus: "Draft", author: '' });

    useEffect(() => {
        dispatch(GetUsers()).then(() => {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
        })
    }, []);

    let currentUser = '';

    useEffect(() => {
        if (users && users.length > 0 && myInfo && Object.keys(myInfo).length > 0) {
            currentUser = users.find(item => item.email === myInfo.email);
            setPost(post => ({
                ...post,
                author: currentUser._id
            }))
            dispatch(GetMediaPosts({ author: currentUser._id })).then(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            })
        }
    }, [users, myInfo])

    useEffect(() => {
        if (posts) {
            // console.log(posts);
        }
    }, [posts]);

    const [loading, setLoading] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);

    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });
    const [selectedImage, setSelectedImage] = useState(null);

    const handledropUpload = (acceptedFiles) => {
        // console.log(acceptedFiles, post);
        setLoading(true);

        setInterval(() => {
            setProgressPercentage(Math.min(progressPercentage + Math.random() * 10, 100));
        }, 1000);

        dispatch(CreateMediaPost({ acceptedFiles: acceptedFiles, author: post.author })).then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (successMessage.create) {
            setCallbackResponse({ type: "success", message: successMessage.create });
            setLoading(false);
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            }, 2000);
        }

        if (successMessage.update) {
            setCallbackResponse({ type: "success", message: successMessage.update });
            setLoading(false);
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            }, 2000);
        }

        if (errorMessage) {
            setCallbackResponse({ type: "error", message: errorMessage });
            setLoading(false);
            dispatch({ type: ERROR_CLEAR })
        }
    }, [successMessage, errorMessage])

    let vertical = 'top';
    let horizontal = 'right';

    const checkRemoteFile = async (url) => {
        return await fetch(url, { method: 'HEAD' })
            .then(response => {
                if (response.ok) {
                    return true; // File exists
                } else {
                    return false; // File does not exist
                }
            })
            .catch(error => {
                console.error('Error checking file existence:', error);
                return false; // File does not exist (or other error occurred)
            });
    }

    const getFileType = (url) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // List of common image file extensions
        const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"]; // List of common video file extensions
        const audioExtensions = ["mp3", "wav", "ogg", "m4a"]; // List of common audio file extensions

        // Extract the file extension from the URL
        const matches = url.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
        if (matches && matches.length > 1) {
            const extension = matches[1].toLowerCase(); // Get the file extension in lowercase

            if (imageExtensions.includes(extension)) {
                return "image"; // Return "image" if it's an image file
            } else if (videoExtensions.includes(extension)) {
                return "video"; // Return "video" if it's a video file
            } else if (audioExtensions.includes(extension)) {
                return "audio"; // Return "audio" if it's an audio file
            } else {
                return "file"; // Return "file" if it's neither image, video, nor audio
            }
        } else {
            return null; // If no file extension found, return null
        }
    }

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "" }, { name: "Media" }]} />
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    // onClose={setCallbackResponse({ type: "", message: "" })}
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>

            <SimpleCard title="All Media">
                <Dropzone onDrop={acceptedFiles => handledropUpload(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ textAlign: 'center', border: '2px dashed', padding: '20px', cursor: 'pointer' }}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <><CloudUploadIcon /><p>Drag 'n' drop some files here, or click to select files</p></>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>

                <ImageList variant="masonry" cols={3} gap={8}>
                    {posts && posts.map((obj) => (
                        <>
                            {obj.posts && obj.posts.map((item) => (
                                <ImageListItem key={item._id} onClick={() => handleOpen(item)}>
                                    <img
                                        srcSet={`${item.postImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.postImage}?w=248&fit=crop&auto=format`}
                                        alt={item.postTitle}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={item.postTitle}
                                        subtitle={item.author}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.postTitle}`}
                                            >
                                                {getFileType(item.postImage) === 'image' ? (
                                                    <ImageIcon />
                                                ) : getFileType(item.postImage) === 'video' ? (
                                                    <VideocamIcon />
                                                ) : getFileType(item.postImage) === 'audio' ? (
                                                    <AudioFileIcon />
                                                ) : (
                                                    <TextSnippetIcon />
                                                )}
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </>
                    ))}
                </ImageList>
            </SimpleCard>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ width: '90%', margin: 'auto', marginTop: '5%', border: 'unset', boxShadow: 'none' }}
            >
                <Item>
                    <Grid container>
                        <Grid item xs={12} sm={8} md={8} lg={8} style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                            <img src={openImage} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Text in a modal
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </Typography>
                        </Grid>
                    </Grid>
                </Item>
            </Modal>
        </Container >
    );
};

export default Media;

