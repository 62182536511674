import { MatxSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { MatxLayouts } from './index';
import { GetCurrentUser } from "../../../store/actions/userAction"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../../config/keys'
import axios from 'axios';
import Swal from 'sweetalert2'

const MatxLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  /* */
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const { error, successMessage, authenticate, myInfo } = useSelector((state) => state.Users);

  // console.log(authenticate);
  // useEffect(() => {
  //   if (!authenticate) {
  //     dispatch(GetCurrentUser())
  //   }
  // }, []);

  return (
    <MatxSuspense>
      <Layout {...props} />
    </MatxSuspense>
  );
};

export default MatxLayout;