import {
    ERROR_CLEAR,
    SUCCESS_MESSAGE_CLEAR,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_FAIL,
    USER_LOGOUT_FAIL_CLEAR,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_SUCCESS_CLEAR,
    USER_CREATE_SUCCESS,
    USER_CREATE_FAIL,
    USER_GET_SUCCESS,
    USER_GET_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    /* RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL */
} from "../types/userTypes";

import axios from "axios";

import { SERVER_URL } from '../../config/keys';

export const userAuth = () => {
    axios.interceptors.request.use(
        (config) => {
            // config.headers.authorization = `Bearer ${token}`;
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            };

            config.withCredentials = true

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const LoginUser = (data) => {
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/user/login`, data, /* {
                headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
                withCredentials: true,
            } */);

            // console.log(response.data);
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    currentUser: response.data.currentUser
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const ForgotMyPassword = (data) => {
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/user/forgot-password`, data);
            dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });
        } catch (error) {
            let data = error?.response?.data?.message;
            // console.log(data)
            dispatch({
                type: FORGOT_PASSWORD_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    };
};

export const ResetMyPassword = (data) => {
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/user/reset-password`, data);
            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message;
            // console.log(data)
            dispatch({
                type: USER_UPDATE_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    };
};

export const GetCurrentUser = (data) => {
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/user/get-current-user`, {});

            // User is authenticated, handle accordingly
            // console.log('User data:', response.data.currentUser);
            // You may want to update your Redux store or perform other actions here
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    // successMessage: response.data.message,
                    successMessage: null,
                    currentUser: response.data.currentUser
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const GetUsers = (data) => {
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/user/get-users`, {});
            // console.log(response)
            dispatch({
                type: USER_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    users: response.data.users
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: USER_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const CreateUser = (data) => {
    // console.log(data)
    userAuth();
    return async (dispatch) => {
        try {
            let response = await axios.post(`${SERVER_URL}/api-v1/user/create`, data);
            dispatch({
                type: USER_CREATE_SUCCESS,
                payload: {
                    users: response.data.users,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: USER_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const LogoutUser = () => {
    return async (dispatch) => {
        try {
            userAuth();
            let response = await axios.post(`${SERVER_URL}/api-v1/user/logout-user`, {});
            // console.log(response)
            dispatch({
                type: USER_LOGOUT_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                },
            });
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: USER_LOGOUT_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    }
}

export const UpdateUser = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.put(`${SERVER_URL}/api-v1/user/update-user`, data);
            // console.log(response)
            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: {
                    users: response.data.users,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: USER_UPDATE_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const DeleteUser = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/user/delete-user`, data);
            // console.log(response)
            dispatch({
                type: USER_DELETE_SUCCESS,
                payload: {
                    users: response.data.users,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: USER_DELETE_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
}