export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'

export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS'
export const COMMENT_CREATE_FAIL = 'COMMENT_CREATE_FAIL'

export const COMMENT_UPDATE_SUCCESS = 'COMMENT_UPDATE_SUCCESS'
export const COMMENT_UPDATE_FAIL = 'COMMENT_UPDATE_FAIL'

export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS'
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL'

export const COMMENT_GET_SUCCESS = 'COMMENT_GET_SUCCESS'
export const COMMENT_GET_FAIL = 'COMMENT_GET_FAIL'
